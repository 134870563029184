<template>
  <div>
    <PanelHeader>Belegungsplan</PanelHeader>
    <v-row>
      <v-col>
        <v-select
          :items="['Tag', '7 Tage']"
          outlined
          dense
          v-model="displayType"
          label="Typ wählen"
        >
        </v-select>
      </v-col>
      <v-col>
        <div style="position: relative">
          <DatePicker :label="dateLabel" v-model="date" />
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <BarChart :chart-data="chartData" :options="chartOptions" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
import DatePicker from "@/components/forms/DatePicker";
import BarChart from "@/components/charts/BarChart";
import PanelHeader from "./PanelHeader.vue";

export default {
  name: "OccupancyPlan",

  components: {
    DatePicker,
    BarChart,
    PanelHeader
  },

  data: () => ({
    date: moment(),
    displayType: "Tag",
    dateLabel: "Tag wählen",
    chartData: {
      datasets: [],
      labels: []
    },
    chartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true, // Start bei 0 erzwingen
              stepSize: 1
            }
          }
        ]
      },
      legend: {
        position: "bottom",
        display: true
      }
    },
    range: 1
  }),

  watch: {
    date: async function(newDate, oldDate) {
      await this.loadData();
    },
    displayType: async function(newType, oldType) {
      console.log(newType, oldType);
      if (newType === "Tag") {
        this.dateLabel = "Tag wählen";
        this.range = 1;
      } else {
        this.dateLabel = "ab Datum wählen (7 Tage; eine Woche)";
        this.range = 7;
      }
      console.log(this.range);
      await this.loadData();
    }
  },

  async created() {},

  methods: {
    async loadData() {
      const data = (await this.fetchData()).data;
      let labels = [];
      let datasets = [];
      const backgroundColors = [
        "#abcdef",
        "#111111",
        "#11dd11",
        "#ff6600",
        "#5000ff",
        "#ee0033",
        "#f5deb3"
      ];
      for (let i = 0; i < data.length; i++) {
        const day = data[i];
        // add labels for each new room
        for (const room of day) {
          if (!labels.find(label => label === room.name)) {
            labels.push(room.name);
          }
        }
        this.date.add(i, "days");
        datasets.push({
          label: this.date.format("DD.MM.YYYY"),
          backgroundColor: backgroundColors[i],
          borderWidth: 0,
          data: []
        });
        for (let j = 0; j < labels.length; j++) {
          datasets[i].data.push(0);
        }
        for (const room of day) {
          datasets[i].data[labels.findIndex(label => label === room.name)] =
            room.booking_count;
        }
        this.date.subtract(i, "days");
      }
      this.chartData = {
        labels: labels,
        datasets: datasets,
        options: this.chartData.options
      };
      console.log(JSON.stringify(this.chartData, null, 4));
    },
    log(...args) {
      console.log(...args);
    },
    fetchData() {
      return this.$api.http.get(
        `backoffice/statistics/occupancy-plan?date=${this.date.format(
          "YYYY-MM-DD"
        )}&range=${this.range}`
      );
    }
  },

  mounted() {
    this.loadData();
  }
};
</script>
