<template>
  <div class="dashboard mt-4 ml-8 mr-8 fill-height">
    <v-container fluid class="fill-height">
      <v-row style="height: 50%;">
        <v-col class="fill-height">
          <v-card outlined tile class="fill-height pa-4 rounded">
            <OccupancyPlan />
          </v-card>
        </v-col>
        <v-col class="fill-height">
          <v-card outlined tile class="fill-height pa-4 rounded">
            <Revenue />
          </v-card>
        </v-col>
      </v-row>
      <v-row style="height: 50%;">
        <v-col class="fill-height">
          <v-card outlined tile class="fill-height pa-4 rounded">
            <TopRooms />
          </v-card>
        </v-col>
        <v-col class="fill-height">
          <v-card outlined tile class="fill-height pa-4 rounded">
            <TopMandates />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import moment from "moment";
import DatePicker from "@/components/forms/DatePicker";
import WeeklyBookingsChart from "@/components/charts/WeeklyBookingsChart";
import DailyRoomsChart from "@/components/charts/DailyRoomsChart";
import OccupancyPlan from "@/components/dashboard/OccupancyPlan";
import TopRooms from "../components/dashboard/TopRooms.vue";
import Revenue from "../components/dashboard/Revenue.vue";
import TopMandates from "../components/dashboard/TopMandates.vue";

export default {
  name: "dashboard",

  components: {
    OccupancyPlan,
    TopRooms,
    Revenue,
    TopMandates
    // WeeklyBookingsChart,
    // DailyRoomsChart
  },

  data: () => ({
    keyFigures: {},
    date: moment(),
    displayType: "Tag",
    dateLabel: "Tag wählen"
  }),

  watch: {
    date: function(newDate, oldDate) {
      console.log(newDate, oldDate);
    },
    displayType: function(newType, oldType) {
      console.log(newType, oldType);
      if (newType === "Tag") {
        this.dateLabel = "Tag wählen";
      } else {
        this.dateLabel = "ab Datum wählen (7 Tage; eine Woche)";
      }
    }
  },

  async created() {
    const response = await this.fetchData();
    this.keyFigures = response.data;
  },

  methods: {
    /**
     * Fetch data from server.
     */
    fetchData() {
      return this.$api.http.get(
        `backoffice/statistics/key-figures?date=${this.date.format(
          "YYYY-MM-DD"
        )}`
      );
    },
    log(...args) {
      console.log(...args);
    }
  }
};
</script>

<style lang="scss">
.dashboard {
  .chart-card {
    border-radius: 0px !important;
  }

  .dashboard-card {
    display: flex;
    flex-direction: row;
    min-height: 100px;

    .dashboard-card-icon {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 30%;
      text-align: center;
      position: relative;

      &.dashboard-gradient-secondary {
        background: radial-gradient(
          circle,
          var(--v-secondary-base),
          var(--v-secondary-darken1)
        );
      }

      &.dashboard-gradient-accent {
        background: radial-gradient(
          circle,
          var(--v-accent-base),
          var(--v-accent-darken1)
        );
      }

      &.dashboard-gradient-primary {
        background: radial-gradient(
          circle,
          var(--v-primary-base),
          var(--v-primary-darken1)
        );
      }

      .dc-icon {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .dashboard-card-content {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 70%;
      text-align: center;
      position: relative;

      .dc-text {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
</style>
