<script>
// import BarChart from "./BarChart.js";
// export default {
// 	components: {
// 		BarChart,
// 	},
// 	data() {
// 		return {
// 			options: { responsive: true, maintainAspectRatio: false },
// 			data: {
// 				labels: this.labels,
// 				datasets: this.datasets,
// 			},
// 		};
// 	},
// 	props: {
// 		datasets: {
// 			required: true,
// 			default: [],
// 		},
// 		labels: {
// 			required: true,
// 			default: [],
// 		},
// 	},
// };
import { Bar, mixins } from "vue-chartjs";
// const { reactiveProp } = mixins;
export default {
  extends: Bar,
  // mixins: [reactiveProp],
  props: ["options", "chartData"],
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
  watch: {
    chartData() {
      this.renderChart(this.chartData, this.options);
      console.log("Chart data updated");
    }
  }
};
</script>
