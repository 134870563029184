<template>
  <v-tabs
    v-model="tab"
    grow
    background-color="grey lighten-3"
    style="margin-left: -16px;margin-right: -16px;margin-top: -16px; width:calc(100% + 32px); pointer-events: none;"
  >
    <v-tabs-slider style="display: hidden; width: 0;"></v-tabs-slider>
    <v-tab style="display: hidden; width: 0;"> </v-tab>
    <v-tab>
      <v-icon class="mr-2" v-if="icon">{{ icon }}</v-icon>
      <slot />
    </v-tab>
    <v-tab style="display: hidden; width: 0;"> </v-tab>
  </v-tabs>
</template>

<script>
export default {
  props: {
    icon: {}
  },
  data() {
    return {
      tab: ""
    };
  }
};
</script>
