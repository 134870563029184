<template>
  <div>
    <PanelHeader>5 beste Mandanten</PanelHeader>
    <v-row>
      <v-col>
        <v-select
          :items="['laufendes Jahr', 'benutzerdefinierter Zeitraum']"
          outlined
          dense
          v-model="displayType"
          label="Typ wählen"
        >
        </v-select>
      </v-col>
      <v-col>
        <div style="position: relative">
          <DatePicker
            label="Von"
            v-model="startDate"
            v-if="displayType == 'benutzerdefinierter Zeitraum'"
          />
        </div>
      </v-col>
      <v-col>
        <div style="position: relative">
          <DatePicker
            label="Bis"
            v-model="endDate"
            v-if="displayType == 'benutzerdefinierter Zeitraum'"
          />
        </div>
      </v-col>
    </v-row>
    <v-row>
      <ol class="pl-16" style="padding-top: 6rem;">
        <li v-for="(item, index) of data" :key="index" class="text-h4">
          {{ index }} ({{
            new Intl.NumberFormat("de-CH", {
              style: "currency",
              currency: "CHF"
            }).format(item)
          }})
        </li>
      </ol>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
import DatePicker from "@/components/forms/DatePicker";
import { watch } from "vue";
import { onMounted } from "vue";
import PanelHeader from "./PanelHeader.vue";

export default {
  components: {
    DatePicker,
    PanelHeader
  },
  data() {
    return {
      startDate: moment([moment().year(), 0, 1]),
      endDate: moment([moment().year(), 11, 31]),
      displayType: "laufendes Jahr",
      data: []
    };
  },
  watch: {
    displayType: async function(newType, oldType) {
      if (newType == "laufendes Jahr") {
        this.startDate = moment([moment().year(), 0, 1]);
        this.endDate = moment([moment().year(), 11, 31]);
      }
      await this.loadData();
    },
    startDate: async function(newDate, oldDate) {
      await this.loadData();
    },
    endDate: async function(newDate, oldDate) {
      await this.loadData();
    }
  },
  methods: {
    fetchData() {
      return this.$api.http.get(
        `backoffice/statistics/top-tenants?startdate=${this.startDate.format(
          "YYYY-MM-DD"
        )}&enddate=${this.endDate.format("YYYY-MM-DD")}`
      );
    },
    async loadData() {
      let data = (await this.fetchData()).data;
      this.data = data;
      console.log(data);
    }
  },
  mounted() {
    this.loadData();
  }
};
</script>
